import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useWizard } from '@oup/shared-front-end/src/components/Wizard';
import WizardStep from '@oup/shared-front-end/src/components/Wizard/WizardStep';
import RadioButtonGroup from '@oup/shared-front-end/src/components/RadioButtonGroup';
import {
  ICON_STUDENT_CHOOSE_ROLE_24,
  ICON_TEACHER_CHOOSE_ROLE_24,
  ICON_PARENT
} from '@oup/shared-front-end/src/svg/oup/index';
import { USER_TYPES } from '../../globals/hubConstants';
import styles from './ChooseRoleWizard.scss';

function ChooseRoleStep({ content, selectedUserType, onChange, onRoleChosen }) {
  const { setIsPrimaryButtonDisabled, handleStep } = useWizard();

  handleStep(() => {
    if (selectedUserType !== USER_TYPES.TEACHER) {
      return;
    }

    onRoleChosen('standard');
  });

  useEffect(() => {
    setIsPrimaryButtonDisabled(!selectedUserType);
  }, [selectedUserType]);

  return (
    <div data-testid="CHOOSE_ROLE_WIZARD_STEP_1">
      <WizardStep titleText={content.choose_role_step_title} className={styles.wizardStep}>
        <RadioButtonGroup
          variant="outline"
          name="userType"
          legend={content.user_type_legend}
          checkedValue={selectedUserType}
          classnames={{ container: styles.radioButtonsContainer }}
          radioGroup={[
            {
              id: USER_TYPES.STUDENT,
              label: content.student_radio_label,
              description: content.student_radio_sublabel,
              value: USER_TYPES.STUDENT,
              image: <ICON_STUDENT_CHOOSE_ROLE_24 />,
              onChange: () => onChange(USER_TYPES.STUDENT),
              className: styles.chooseRoleRadioButton
            },
            {
              id: USER_TYPES.PARENT,
              label: content.parent_radio_label,
              description: content.parent_radio_sublabel,
              value: USER_TYPES.PARENT,
              image: <ICON_PARENT />,
              onChange: () => onChange(USER_TYPES.PARENT),
              className: styles.chooseRoleRadioButton
            },
            {
              id: USER_TYPES.TEACHER,
              label: content.teacher_radio_label,
              description: content.teacher_radio_sublabel,
              value: USER_TYPES.TEACHER,
              image: <ICON_TEACHER_CHOOSE_ROLE_24 />,
              onChange: () => onChange(USER_TYPES.TEACHER),
              className: styles.chooseRoleRadioButton
            }
          ]}
        />
      </WizardStep>
    </div>
  );
}

ChooseRoleStep.propTypes = {
  content: PropTypes.object.isRequired,
  selectedUserType: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onRoleChosen: PropTypes.func.isRequired
};

export default ChooseRoleStep;
