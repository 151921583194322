import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RadioButtonGroup from '@oup/shared-front-end/src/components/RadioButtonGroup';
import { OPT_YL } from '../../../../components/PlacementTestOnBoardingWizard/utils/constants';
import styles from './PlacementTestLanguageVariation.scss';
import withLocalizedContent from '../../../../language/withLocalizedContent';
import {
  setEditedPlacementTest,
  setPlacementTestProductChanged
} from '../../../../redux/reducers/placementTestSessionCreate';

function PlacementTestLanguageVariation({
  isWizard,
  content,
  localizedContent: { placementTests: placementTestsContent },
  setIsLanguageVariationTouchedCallback,
  setSelectedProductCallback,
  setPlacementTestTypeCallback,
  setLanguageVariationCodeCallback,
  setListeningAccentsCodeCallback,
  setOriginalProductCallback,
  listeningAccentsCodeProp = '',
  languageVariationCodeProp = '',
  placementTestTypeProp = ''
}) {
  const [showLanguageVariation, setShowLanguageVariation] = useState(false);
  const [showAccents, setShowAccents] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [placementTestType, setPlacementTestType] = useState(placementTestTypeProp);
  const [languageVariationCode, setLanguageVariationCode] = useState(languageVariationCodeProp);
  const [listeningAccentsCode, setListeningAccentsCode] = useState(listeningAccentsCodeProp);

  useEffect(() => {
    if (placementTestType && content && content.length) {
      const selected = placementTestType === OPT_YL ? content[1] : content[0];
      setSelectedProduct(selected);
      setPlacementTestType(selected.title);
      if (isWizard) {
        setPlacementTestTypeCallback(selected.title);
        setSelectedProductCallback(selected);
        setOriginalProductCallback(selected.productid);
      }
    }
  }, []);

  useEffect(() => {
    if (languageVariationCode) setShowLanguageVariation(true);
  }, [languageVariationCode]);

  useEffect(() => {
    if (listeningAccentsCode) setShowAccents(true);
  }, [listeningAccentsCode]);

  const handleLanguageVariationSelection = e => {
    const chosenLanguageVariation = selectedProduct.language_variations.find(
      languageVariation => languageVariation.language_variation_code.toString() === e.target.value.toString()
    );

    setLanguageVariationCode(chosenLanguageVariation.language_variation_code);
    setLanguageVariationCodeCallback(chosenLanguageVariation.language_variation_code);
    setListeningAccentsCode('');
    setListeningAccentsCodeCallback('');
    setShowAccents(true);
    if (!isWizard) {
      setIsLanguageVariationTouchedCallback(false);
    }
  };

  const handleListeningAccentSelection = e => {
    const chosenLanguageVariation = selectedProduct.language_variations.find(
      languageVariation => languageVariation.language_variation_code.toString() === languageVariationCode
    );
    const choosenListeningAccents = chosenLanguageVariation.listening_accents.find(
      listeningAccent => listeningAccent['assessment_master:package_org_code'] === e.target.value
    );
    setListeningAccentsCode(choosenListeningAccents['assessment_master:package_org_code']);
    setListeningAccentsCodeCallback(choosenListeningAccents['assessment_master:package_org_code']);
    if (!isWizard) {
      setIsLanguageVariationTouchedCallback(true);
    }
  };

  const handleCheckboxSelection = e => {
    const selected = content.filter(product => product.title === e.target.value)[0];
    setPlacementTestTypeCallback(selected.title);
    setPlacementTestType(selected.title);
    setSelectedProduct(selected);
    setSelectedProductCallback(selected);

    if (selected && !selected.language_variations.length) {
      setLanguageVariationCode('');
      setLanguageVariationCodeCallback('');
      setListeningAccentsCode(OPT_YL);
      setListeningAccentsCodeCallback(OPT_YL);
      setShowLanguageVariation(false);
      setShowAccents(false);
    } else {
      setListeningAccentsCode('');
      setListeningAccentsCodeCallback('');
      setShowLanguageVariation(true);
    }
  };

  return (
    <div>
      {isWizard && (
        <div className={styles.placementTestLanguageVariationColumn}>
          <RadioButtonGroup
            variant="outline"
            name="placement-type-group-1"
            legend="legend name test 1"
            checkedValue={[placementTestType]}
            classnames={{ container: styles.placementTypeRadioButtonGroupContainer }}
            radioGroup={content.map((product, index) => ({
              id: `placement-type-radio-${index}`,
              image: <img src={product.product_image} alt={product.title} />,
              value: product.title
            }))}
            onChange={handleCheckboxSelection}
            hideLabel
          />
        </div>
      )}
      <div className="pad2">
        {!isWizard && (
          <div className={styles.languageVariationsContainer}>
            <div>
              <img src={selectedProduct.cover} alt={selectedProduct.title} />
            </div>
          </div>
        )}
        {showLanguageVariation && selectedProduct && (
          <div className={styles.languageVariationsCheckboxes}>
            <h3>{placementTestsContent.use_of_english}</h3>
            <RadioButtonGroup
              variant="outline"
              name="language-variation-group-2"
              legend="legend name test 2"
              checkedValue={[languageVariationCode]}
              classnames={{ container: styles.languageVariationRadioButtonGroupContainer }}
              radioGroup={selectedProduct.language_variations.map((variation, index) => ({
                id: `language-variation-radio-${index}`,
                label: placementTestsContent[variation.language_variation_code],
                value: variation.language_variation_code
              }))}
              onChange={handleLanguageVariationSelection}
            />
          </div>
        )}
        {showAccents && languageVariationCode && selectedProduct && (
          <div className={styles.languageVariationsCheckboxes}>
            <h3>{placementTestsContent.listening_accents}</h3>
            <RadioButtonGroup
              variant="outline"
              name="listening-accent-variation-group-3"
              legend="legend name test 3"
              checkedValue={[listeningAccentsCode]}
              classnames={{ container: styles.accentsRadioButtonGroupContainer }}
              radioGroup={selectedProduct.language_variations
                .find(
                  languageVariation => languageVariation.language_variation_code.toString() === languageVariationCode
                )
                .listening_accents.map((accent, accentIndex) => ({
                  id: `listening-accent-variation-radio-${accentIndex}`,
                  label: placementTestsContent[accent['assessment_master:package_org_code']],
                  value: accent['assessment_master:package_org_code']
                }))}
              onChange={handleListeningAccentSelection}
            />
          </div>
        )}
      </div>
    </div>
  );
}

PlacementTestLanguageVariation.propTypes = {
  content: PropTypes.object,
  localizedContent: PropTypes.object.isRequired,
  isWizard: PropTypes.bool,
  setIsLanguageVariationTouchedCallback: PropTypes.func,
  setSelectedProductCallback: PropTypes.func,
  languageVariationCodeProp: PropTypes.string,
  listeningAccentsCodeProp: PropTypes.string,
  placementTestTypeProp: PropTypes.string,
  setPlacementTestTypeCallback: PropTypes.func,
  setLanguageVariationCodeCallback: PropTypes.func,
  setListeningAccentsCodeCallback: PropTypes.func,
  setOriginalProductCallback: PropTypes.func
};

export default compose(
  withLocalizedContent('placementTests'),
  connect(
    ({ placementTestSessionCreate, contentPreview }) => {
      const placementTest = placementTestSessionCreate.placementTest;
      const editedPlacementTest = placementTestSessionCreate.editedPlacementTest;
      return {
        content: contentPreview.data || contentPreview.results,
        placementTestTypeProp: placementTest.placementTestType,
        listeningAccentsCodeProp: editedPlacementTest?.listeningAccentsCode || placementTest.listeningAccentsCode,
        languageVariationCodeProp: editedPlacementTest?.languageVariationCode || placementTest.languageVariationCode,
        placementTestSessionId: placementTestSessionCreate.placementTestSessionId
      };
    },
    {
      placementTestProductChangedAction: setPlacementTestProductChanged,
      setEditedPlacementTestAction: setEditedPlacementTest
    }
  )
)(PlacementTestLanguageVariation);
