import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect, useSelector } from 'react-redux';
import Footer from '@oup/shared-front-end/src/components/Wizard/Footer';
import { ICON_LEFT } from '@oup/shared-front-end/src/svg/oup';
import APP_CONSTANTS from '@oup/shared-node-browser/constants';
import TextLink from '@oup/shared-front-end/src/components/TextLink';
import NumberInput from '@oup/shared-front-end/src/components/NumberInput/NumberInput.js';
import { PopoutPanelIconHeading } from '../../../index';
import { types as popoutPanelIconHeadingTypes } from '../../../PopoutPanelIconHeading/PopoutPanelIconHeading';
import PanelHeading from '../../../PanelHeading/PanelHeading';
import ScrollContainer from '../../../ScrollContainer/ScrollContainer';
import withLocalizedContent from '../../../../language/withLocalizedContent';
import styles from './SetNumberOfPlacesForUnknownStudentsPanel.scss';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../../../globals/hubConstants.js';
import PopoutPanelIllustrationHeading from '../../../PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading.js';
import PopoutActionFooter from '../../../PopoutActionFooter/PopoutActionFooter.js';
import {
  updatePlacementTestSessionRequest,
  clearPlacementTestSessionUpdateState
} from '../../../../redux/actions/placementTestOnboardingWizard.js';
import { setPlacementTest } from '../../../../redux/reducers/placementTestSessionCreate';

function PlacementTestSetNumberOfPlacesForUnknownStudentsPanel({
  onClose,
  placementTestSessionName,
  localizedContent: { placementTests: placementTestContent, hubGlossary: hubContent },
  onBack,
  placesForUnknownStudents = 0,
  setPlacementTestAction,
  updatePlacementTestAction,
  clearPlacementTestSessionUpdateStateAction,
  placementTestSessionUpdated,
  placementTestSessionUpdateFailed
}) {
  const { orgId, sessionId } = useSelector(({ placementTestSessionCreate, placementOnboardingWizard, identity }) => ({
    sessionId:
      placementTestSessionCreate.placementTestSessionId || placementOnboardingWizard?.placementTestSessionIdCreated,
    orgId: identity.currentOrganisationId
  }));

  const [numberOfStudents, setNumberOfStudents] = useState(placesForUnknownStudents);

  const initialState = {
    loading: false,
    success: false,
    fail: false
  };

  const [{ loading, success, fail }, setState] = useState(initialState);

  const onChangeHandler = e => {
    const newVal = Number(e.target.value);
    if (newVal) setNumberOfStudents(newVal);
  };

  useEffect(() => {
    setState({ loading: false, success: placementTestSessionUpdated, fail: placementTestSessionUpdateFailed });
    if (placementTestSessionUpdated) {
      setPlacementTestAction({ placesForUnknownStudents: numberOfStudents });
    }
  }, [placementTestSessionUpdated, placementTestSessionUpdateFailed]);

  useEffect(() => {
    setNumberOfStudents(placesForUnknownStudents);
  }, [placesForUnknownStudents]);

  const handleClose = () => {
    setState(initialState);
    clearPlacementTestSessionUpdateStateAction();
    onClose();
  };

  const handleTryAgain = () => {
    setState(initialState);
    clearPlacementTestSessionUpdateStateAction();
  };

  const handleNextBtn = () => {
    setState({ ...initialState, loading: true });
    updatePlacementTestAction({
      orgId,
      sessionId,
      placementTest: {
        placesForUnknownStudents: numberOfStudents
      }
    });
  };

  const renderFooter = () => {
    if (loading) {
      return null;
    }
    if (success) {
      return <PopoutActionFooter primaryButtonText={hubContent.done_button} primaryButtonAction={handleClose} />;
    }
    if (fail) {
      return <PopoutActionFooter primaryButtonText={hubContent.try_again} primaryButtonAction={handleTryAgain} />;
    }
    return (
      <Footer
        primaryButtonLabel={placementTestContent.set_number_of_places_for_unknown_students_panel_submit_button}
        secondaryButtonLabel={hubContent.button_back_text}
        primaryButtonAction={handleNextBtn}
        secondaryButtonAction={onBack}
        primaryButtonAttributes={{
          dataAttributes: {
            testid: 'PLACEMENT_TEST_ONBOARDING_WIZARD_SET_NUMBER_OF_PLACES_FOR_UNKNOWN_STUDENTS_MODAL_NEXT_BUTTON'
          }
        }}
        secondaryButtonAttributes={{
          icon: <ICON_LEFT />,
          dataAttributes: {
            testid: 'PLACEMENT_TEST_ONBOARDING_WIZARD_SET_NUMBER_OF_PLACES_FOR_UNKNOWN_STUDENTS_MODAL_CANCEL_BUTTON'
          }
        }}
      />
    );
  };

  const renderHeader = () => {
    if (loading || success || fail) return null;
    return (
      <PanelHeading
        title={placementTestContent.set_number_of_places_for_unknown_students_panel_title}
        subtitle={(placementTestContent.set_number_of_places_for_unknown_students_panel_subtitle || '').replace(
          '[sessionName]',
          placementTestSessionName
        )}
      />
    );
  };

  const renderContent = () => {
    if (loading) {
      return (
        <PopoutPanelIconHeading
          type={popoutPanelIconHeadingTypes.LOADING}
          title={placementTestContent.waiting_message_title}
          subtitle={placementTestContent.waiting_message_subtitle}
        />
      );
    }
    if (success) {
      return (
        <div className={styles.setNumberOfPlacesResponseContainer}>
          <PopoutPanelIllustrationHeading
            type={popoutPanelIconHeadingTypes.VALID}
            title={(placementTestContent.set_number_of_places_for_unknown_students_panel_success_title || '')
              .replace('[numberOfPlaces]', numberOfStudents)
              .replace('[sessionName]', placementTestSessionName)}
            subtitle={(
              placementTestContent.set_number_of_places_for_unknown_students_panel_success_subtitle || ''
            ).replace('[numberOfPlaces]', numberOfStudents)}
            illustrationSrc={HubIllustrationConstants.SUCCESS}
            illustrationAltText={HubIllustrationAltText.SUCCESS}
          />
          <TextLink target="_blank" to={APP_CONSTANTS.HUB_SUPPORT_CONTACT_US}>
            {placementTestContent.placement_test_add_students_page_learn_about_joining_codes}
          </TextLink>
        </div>
      );
    }
    if (fail) {
      return (
        <>
          <div className={styles.setNumberOfPlacesResponseContainer}>
            <PopoutPanelIllustrationHeading
              type={popoutPanelIconHeadingTypes.ERROR}
              title={placementTestContent.set_number_of_places_for_unknown_students_panel_fail_title}
              illustrationSrc={HubIllustrationConstants.ERROR}
              illustrationAltText={HubIllustrationAltText.ERROR}
            />
          </div>
          <hr />
        </>
      );
    }
    return (
      <div className={styles.setNumberOfPlacesContent}>
        <NumberInput
          onChange={onChangeHandler}
          label={placementTestContent.placement_test_set_number_of_places_for_unknown_students_label}
          value={numberOfStudents}
          min={0}
          max={100000}
        />
        <div className={styles.infoSection}>
          <p>
            {numberOfStudents > 0
              ? placementTestContent.set_number_of_places_for_unknown_students_panel_success_subtitle.replace(
                  '[numberOfPlaces]',
                  numberOfStudents
                )
              : placementTestContent.placement_test_set_number_of_places_for_unknown_students_info}
          </p>
          <TextLink target="_blank" to={APP_CONSTANTS.HUB_SUPPORT_CONTACT_US}>
            {placementTestContent.placement_test_add_students_page_learn_about_joining_codes}
          </TextLink>
        </div>
      </div>
    );
  };
  return (
    <ScrollContainer headerContent={renderHeader()} footerContent={renderFooter()}>
      {renderContent()}
    </ScrollContainer>
  );
}

PlacementTestSetNumberOfPlacesForUnknownStudentsPanel.propTypes = {
  onClose: PropTypes.func.isRequired,
  placementTestSessionName: PropTypes.string.isRequired,
  localizedContent: PropTypes.object.isRequired,
  onBack: PropTypes.func,
  placesForUnknownStudents: PropTypes.number,
  setPlacementTestAction: PropTypes.func,
  updatePlacementTestAction: PropTypes.func,
  placementTestSessionUpdated: PropTypes.bool,
  placementTestSessionUpdateFailed: PropTypes.bool,
  clearPlacementTestSessionUpdateStateAction: PropTypes.func
};

export default compose(
  withLocalizedContent('placementTests', 'hubGlossary'),
  connect(
    ({ placementTestSessionCreate, placementOnboardingWizard }) => ({
      placesForUnknownStudents: placementTestSessionCreate.placementTest.placesForUnknownStudents,
      placementTestSessionUpdated: placementOnboardingWizard.placementTestSessionUpdated,
      placementTestSessionUpdateFailed: placementOnboardingWizard.placementTestSessionCreationError
    }),
    {
      setPlacementTestAction: setPlacementTest,
      updatePlacementTestAction: updatePlacementTestSessionRequest,
      clearPlacementTestSessionUpdateStateAction: clearPlacementTestSessionUpdateState
    }
  )
)(PlacementTestSetNumberOfPlacesForUnknownStudentsPanel);
