import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import WizardStep from '@oup/shared-front-end/src/components/Wizard/WizardStep';
import TextLink from '@oup/shared-front-end/src/components/TextLink';
import SVGIcon, { GLYPHS } from '../../SVGIcon/SVGIcon.js';
import ClassCode from '../ClassCode/ClassCode.js';
import userRoles from '../../../globals/userRoles.js';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../../globals/hubConstants.js';
import preventBodyScroll from '../../../utils/dom/preventBodyScroll.js';
import styles from './Summary.scss';

function Summary({
  userRole,
  classNameValue,
  students,
  learningMaterials,
  content,
  joiningCode = '',
  customOrgId = '',
  isOpen
}) {
  useEffect(() => {
    preventBodyScroll(isOpen);
  });

  const numberOfStudentsAdded = Object.keys(students).length;
  const numberOfLearningMaterialsAdded = learningMaterials.length;

  const renderSuccessIcon = () => (
    <div className={styles.svgContainer}>
      <SVGIcon glyph={GLYPHS.ICON_CHECK_CIRCLE} className={styles.infoIcon} />
    </div>
  );

  const renderInfoIcon = () => (
    <div className={styles.svgContainer}>
      <SVGIcon glyph={GLYPHS.ICON_INFORMATION_CIRCLE} className={styles.infoIcon} />
    </div>
  );

  const renderNumberOfStudentsText = () => {
    if (numberOfStudentsAdded === 0) {
      return content.no_students;
    }

    if (numberOfStudentsAdded === 1) {
      return `1 ${content.student}`;
    }

    return `${numberOfStudentsAdded} ${content.students}`;
  };

  const renderLearningMaterialSummary = () => {
    if (numberOfLearningMaterialsAdded === 0) {
      return (
        <>
          <span className={styles.boldText}>{content.no_materials}</span>
          <span> {content.added_to_the_class}</span>
        </>
      );
    }

    const firstTitle = learningMaterials?.[0].title;

    if (numberOfLearningMaterialsAdded === 1) {
      return (
        <>
          <span className={styles.boldText}>{firstTitle}</span>
          <span> {content.is_being_assigned}</span>
        </>
      );
    }

    if (numberOfLearningMaterialsAdded === 2) {
      return (
        <>
          <span className={styles.boldText}>{firstTitle}</span>
          <span> and </span>
          <span className={styles.boldText}>{content.other}</span>
          <span> {content.are_being_assigned}</span>
        </>
      );
    }

    return (
      <>
        <span className={styles.boldText}>{firstTitle}</span>
        <span> and </span>
        <span className={styles.boldText}>{`${numberOfLearningMaterialsAdded - 1} ${content.others}`}</span>
        <span> {content.are_being_assigned}</span>
      </>
    );
  };

  return (
    <div data-testid="ONBOARDING_WIZARD_SUMMARY_CONTAINER">
      <WizardStep
        titleText={`${classNameValue} ${content.summary_title}`}
        image={HubIllustrationConstants.SUCCESS}
        imageAlt={HubIllustrationAltText.SUCCESS}
        className={styles.summaryStepContainer}
      >
        <div>
          {userRole === userRoles.ORG_ADMIN || userRole === userRoles.TEACHER_ADMIN ? (
            <ClassCode customOrgId={customOrgId} joiningCode={joiningCode} />
          ) : null}

          <div className={styles.summaryDetails}>
            <div className={styles.detailsRow}>
              {numberOfStudentsAdded >= 1 && renderSuccessIcon()}
              {numberOfStudentsAdded === 0 && renderInfoIcon()}
              <div>
                <span className={styles.boldText}>{renderNumberOfStudentsText()}</span>
                <span>{` ${content.added_to_the_class}`}</span>
              </div>
            </div>

            {numberOfStudentsAdded === 0 && userRole === userRoles.TEACHER ? (
              <div className={styles.noStudentsInfoContainer}>
                <span>{content.no_students_info_for_teachers}</span>

                <TextLink to={content.support_page_give_students_a_join_code_link} target="_blank">
                  {content.learn_more}
                </TextLink>
              </div>
            ) : null}

            <div className={styles.detailsRow}>
              {numberOfLearningMaterialsAdded >= 1 && renderSuccessIcon()}
              {numberOfLearningMaterialsAdded === 0 && renderInfoIcon()}
              <div>{renderLearningMaterialSummary()}</div>
            </div>
          </div>
        </div>
      </WizardStep>
    </div>
  );
}

Summary.propTypes = {
  userRole: PropTypes.string.isRequired,
  classNameValue: PropTypes.string.isRequired,
  students: PropTypes.object.isRequired,
  learningMaterials: PropTypes.array.isRequired,
  content: PropTypes.object.isRequired,
  joiningCode: PropTypes.string.isRequired,
  customOrgId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired
};

export default connect(
  ({
    identity: { currentOrganisationId: orgId = '', role: userRole = '' } = {},
    classOnboardingWizard: {
      classNameValue,
      userDetails: { students = {} } = {},
      learningMaterialDetails: { learningMaterials = [] } = {},
      joiningCode: { code: joiningCode = '' } = {}
    } = {},
    organisations
  }) => ({
    userRole,
    classNameValue,
    learningMaterials,
    students,
    joiningCode,
    customOrgId: organisations?.data[orgId]?.customId
  }),
  null
)(Summary);
