import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useWizard } from '@oup/shared-front-end/src/components/Wizard';
// Components
import WizardStep from '@oup/shared-front-end/src/components/Wizard/WizardStep';
import Button from '@oup/shared-front-end/src/components/Button';
import TextLink from '@oup/shared-front-end/src/components/TextLink';
import SVGIcon, { GLYPHS } from '../../SVGIcon/SVGIcon';
import UserRepresentation from '../../UserRepresentation/UserRepresentation';
import AddStudentsPanel from '../../../routes/ClassroomPage/tabs/StudentsTab/panels/AddStudents/AddStudents';
import ClassCode from '../ClassCode/ClassCode.js';
// Utils
import preventBodyScroll from '../../../utils/dom/preventBodyScroll';
// Redux
import { getClassStudents } from '../../../redux/actions/classOnboardingWizard';
import { formStates } from '../../../redux/reducers/addStudentsToClassroom';
// Constants
import userRoles from '../../../globals/userRoles';
// Styles
import styles from './AddStudents.scss';

function AddStudents({
  orgId,
  userRole,
  classId,
  className,
  content,
  numberOfStudents = 0,
  formState,
  addStudentsRequestFailed,
  getClassStudentsAction,
  studentsData = {},
  joiningCode = '',
  customOrgId = '',
  isOpen
}) {
  const { setIsPrimaryButtonDisabled } = useWizard();
  const [isPanelOpen, togglePanel] = useState(false);

  useEffect(() => {
    setIsPrimaryButtonDisabled(formState === formStates.SUBMITTING);
  }, [formState]);

  useEffect(() => {
    if (formState === formStates.CONFIRMATION && addStudentsRequestFailed === false) {
      getClassStudentsAction({ orgId, classId });
    }
  }, [addStudentsRequestFailed]);

  useEffect(() => {
    preventBodyScroll(isOpen);
  });

  const handleAddStudentsClick = () => {
    togglePanel(true);
  };

  const getStudentsList = () =>
    Object.entries(studentsData).map(([userId, user]) => (
      <div key={userId} data-testid={`ONBOARDING_WIZARD_STUDENT_${userId}`} className={styles.studentItemContainer}>
        <UserRepresentation
          firstName={user.firstname || ''}
          lastName={user.lastname || ''}
          identifier={user.email}
          role={user.roleName}
          locked={user.isLocked}
        />
      </div>
    ));

  const getClassCodeInfoContainer = () => {
    if (userRole === userRoles.ORG_ADMIN || userRole === userRoles.TEACHER_ADMIN) {
      return (
        <div className={styles.classCodeInfoContainer}>
          <p className={styles.boldText}>{content.class_code}</p>
          <p>
            {content.class_code_info_for_org_admins_1}
            <span className={styles.boldText}>{content.class_code.toLowerCase()}</span>
            {content.class_code_info_for_org_admins_2}
          </p>
          <ClassCode customOrgId={customOrgId} joiningCode={joiningCode} />
          <TextLink to={content.support_page_give_students_a_join_code_link_admins} target="_blank">
            {content.learn_more_about_class_codes}
          </TextLink>
        </div>
      );
    }

    if (userRole === userRoles.TEACHER) {
      return (
        <div className={styles.classCodeInfoContainer}>
          <p>
            {content.class_code_info_for_teachers_1}
            <span className={styles.boldText}>{content.class_code.toLowerCase()}.</span>
            {content.class_code_info_for_teachers_2}
          </p>
          <TextLink to={content.support_page_give_students_a_join_code_link} target="_blank">
            {content.learn_more_about_class_codes}
          </TextLink>
        </div>
      );
    }

    return null;
  };

  return (
    <div data-testid="ONBOARDING_WIZARD_ADD_STUDENTS_CONTAINER" className={styles.stepContainer}>
      <WizardStep titleText={`${content.add_students_title} ${className}`} subtitleText={content.add_students_info}>
        <div className={styles.contentContainer}>
          <div className={styles.contentColumn}>
            <div className={styles.buttonContainer}>
              <Button
                dataAttributes={{ testId: 'ONBOARDING_WIZARD_ADD_STUDENTS_BUTTON' }}
                text={content.addStudents}
                onClick={handleAddStudentsClick}
                variant="outline"
                icon={{ placement: 'left', component: <SVGIcon glyph={GLYPHS.ICON_PLUS} /> }}
              />
            </div>
            {numberOfStudents > 0 ? <div className={styles.studentsListContainer}>{getStudentsList()}</div> : null}
          </div>
        </div>
      </WizardStep>
      <div className={styles.rightColumnContainer}>{getClassCodeInfoContainer()}</div>
      <AddStudentsPanel
        isOpen={isPanelOpen}
        closePanel={() => togglePanel(!isPanelOpen)}
        onComplete={() => togglePanel(!isPanelOpen)}
        orgId={orgId}
        classId={classId}
      />
    </div>
  );
}

AddStudents.propTypes = {
  orgId: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  classId: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
  numberOfStudents: PropTypes.number.isRequired,
  studentsData: PropTypes.object,
  formState: PropTypes.string.isRequired,
  addStudentsRequestFailed: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  getClassStudentsAction: PropTypes.func,
  joiningCode: PropTypes.string,
  customOrgId: PropTypes.string,
  isOpen: PropTypes.bool.isRequired
};

export default connect(
  ({
    identity: { currentOrganisationId: orgId = '', role: userRole = '' } = {},
    classOnboardingWizard: {
      classId = '',
      classNameValue: className = '',
      userDetails: { students: studentsData = {} } = {},
      joiningCode: { code: joiningCode = '' } = {}
    } = {},
    addStudentsToClassroom: { formState = '', requestFailed: addStudentsRequestFailed = null } = {},
    organisations: { data: organisationsData = {} } = {}
  }) => ({
    orgId,
    userRole,
    classId,
    className,
    numberOfStudents: Object.keys(studentsData).length,
    studentsData,
    formState,
    addStudentsRequestFailed,
    joiningCode,
    customOrgId: organisationsData[orgId]?.customId
  }),
  { getClassStudentsAction: getClassStudents }
)(AddStudents);
