import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useWizard } from '@oup/shared-front-end/src/components/Wizard';
import WizardStep from '@oup/shared-front-end/src/components/Wizard/WizardStep';
import RadioButtonGroup from '@oup/shared-front-end/src/components/RadioButtonGroup';
import { ICON_STUDENT_UNDER_12, ICON_STUDENT_OVER_12 } from '@oup/shared-front-end/src/svg/oup/index';
import { USER_TYPES, USER_AGE } from '../../globals/hubConstants';
import styles from './ChooseRoleWizard.scss';

function ChooseAgeStep({ content, selectedUserType, selectedUserAge, onChange, onRoleChosen }) {
  const { setIsPrimaryButtonDisabled, handleStep } = useWizard();

  useEffect(() => {
    setIsPrimaryButtonDisabled(!selectedUserAge);
  }, [selectedUserAge]);

  handleStep(() => {
    if (selectedUserAge === USER_AGE.UNDER_12) {
      onRoleChosen('youngLearner');
      return;
    }
    onRoleChosen('standard');
  });

  return (
    <div data-testid="CHOOSE_ROLE_WIZARD_STEP_2">
      <WizardStep
        titleText={selectedUserType === USER_TYPES.STUDENT ? content.how_old_are_you : content.how_old_is_your_child}
        className={styles.wizardStep}
      >
        <RadioButtonGroup
          variant="outline"
          name="userAge"
          legend={
            selectedUserType === USER_TYPES.STUDENT
              ? content.choose_age_as_learner_legend
              : content.choose_age_as_parent_legend
          }
          classnames={{ container: styles.radioButtonsContainer }}
          checkedValue={selectedUserAge}
          radioGroup={[
            {
              id: USER_AGE.UNDER_12,
              label: content.under_12_years_old,
              value: USER_AGE.UNDER_12,
              image: <ICON_STUDENT_UNDER_12 />,
              onChange: () => onChange(USER_AGE.UNDER_12),
              className: styles.chooseAgeRadioButton
            },
            {
              id: USER_AGE.OVER_12,
              label: content.over_12_years_old,
              value: USER_AGE.OVER_12,
              image: <ICON_STUDENT_OVER_12 />,
              onChange: () => onChange(USER_AGE.OVER_12),
              className: styles.chooseAgeRadioButton
            }
          ]}
        />
      </WizardStep>
    </div>
  );
}

ChooseAgeStep.propTypes = {
  content: PropTypes.string.isRequired,
  selectedUserType: PropTypes.string.isRequired,
  selectedUserAge: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onRoleChosen: PropTypes.func.isRequired
};

export default ChooseAgeStep;
