import React from 'react';
import { get, includes } from 'lodash';
import { roleIsAtLeast, userRoles } from '@oup/shared-node-browser/user.js';
import { featureIsEnabled } from '../../../globals/envSettings.js';
import { getCoursesLinksData } from '../../HubProductLayout/Services/getCoursesLinksData.js';
import { getClassesLinksData } from '../../HubProductLayout/Services/getClassesLinksData.js';
import { HubLayoutConstants, HubIllustrationConstants, HubIllustrationAltText } from '../../../globals/hubConstants.js';
import {
  openEditOrgModal,
  openEditClassroomModal,
  openOnboardingWizard,
  openJoinClassWithCodeModal,
  openRedeemModal
} from '../../../redux/actions/hubUi';
import { getOrgLinksData } from '../../HubOrganizationLayout/Services/getOrgLinksData.js';
import store from '../../../redux/store';
import getCourseLevel from '../Services/getCourseLevel.js';
import SVGIcon, { GLYPHS } from '../../../components/SVGIcon/SVGIcon.js';
import { isLtiMode } from '../../../utils/platform.js';
import processUserRole from '../Services/processUserRole.js';
import styles from '../HubDashboardLayout.scss';
import CopyToClipboardButton from '../../../components/CopyToClipboardButton/CopyToClipboardButton.js';

function getPageLayoutData({
  pathname,
  content,
  course,
  classData,
  orgData,
  userData,
  isLmsLtiTool,
  currentOrganisationLti
}) {
  switch (true) {
    case pathname.includes(HubLayoutConstants.PATH_NAMES.MY_PROGRESS): {
      return {
        showInnerPageHero: false,
        pageHero: {
          title: content.my_progress,
          illustration: HubIllustrationConstants.STUDENT,
          illustrationAltText: HubIllustrationAltText.STUDENT,
          className: styles.kipperYellow
        }
      };
    }
    case pathname.includes(HubLayoutConstants.PATH_NAMES.COURSES_PATH) &&
      !pathname.includes(HubLayoutConstants.PATH_NAMES.SINGLE_COURSE_PATH):
      return {
        showInnerPageHero: false,
        pageHero: {
          title: content.my_courses,
          illustration: HubIllustrationConstants.DIGITAL_LEARNING,
          illustrationAltText: HubIllustrationAltText.DIGITAL_LEARNING,
          className: styles.kipperYellow
        },
        stickyButton: {
          text: featureIsEnabled('opt-main-features') ? content.enter_a_code : content.redeem_code,
          icon: { component: <SVGIcon glyph={GLYPHS.ICON_PLUS} /> },
          onClick: () => store.dispatch(openRedeemModal())
        }
      };
    case pathname.includes(HubLayoutConstants.PATH_NAMES.CLASSES_PATH) &&
      !pathname.includes(HubLayoutConstants.PATH_NAMES.SINGLE_CLASS_PATH): {
      const { userRole } = userData;
      return {
        showInnerPageHero: false,
        pageHero: {
          title: content.my_classes,
          illustration: HubIllustrationConstants.CLASS_STUDENTS,
          illustrationAltText: HubIllustrationAltText.CLASS_STUDENTS,
          className: styles.kipperYellow
        },
        stickyButton: {
          text: roleIsAtLeast(userRoles.TEACHER, userRole) ? content.create_class : content.join_class,
          icon: { component: <SVGIcon glyph={GLYPHS.ICON_PLUS} /> },
          onClick: () =>
            roleIsAtLeast(userRoles.TEACHER, userRole)
              ? store.dispatch(openOnboardingWizard())
              : store.dispatch(openJoinClassWithCodeModal())
        }
      };
    }
    case featureIsEnabled('navigation-changes') &&
      pathname.includes(HubLayoutConstants.PATH_NAMES.SINGLE_COURSE_PATH) &&
      !!course: {
      return {
        /*
          meaning of showInnerPageHero is that the pageHero gets rendered inside the children ( in HubPageLayout)
          when showInnerPageHero is true, while it gets rendered on HubDashboardLayout when its false
        */
        showInnerPageHero: true,
        secondaryNavigation: {
          links: getCoursesLinksData({ content, course, pathname })
        },
        pageHero: {
          title: course.title,
          children: course.properties ? getCourseLevel(course.properties) : '',
          className: styles.neutralBlue
        }
      };
    }
    case featureIsEnabled('navigation-changes') &&
      pathname.includes(HubLayoutConstants.PATH_NAMES.ORGANIZATION_PATH) &&
      !pathname.includes(HubLayoutConstants.PATH_NAMES.SINGLE_CLASS_PATH): {
      const { userRole } = userData;
      const { orgName, orgConfig } = orgData;

      return {
        showInnerPageHero: true,
        secondaryNavigation: {
          links: getOrgLinksData({ content, pathname, isLmsLtiTool, currentOrganisationLti, orgConfig }),
          ...(roleIsAtLeast(userRoles.ORG_ADMIN, userRole) && {
            settingsButton: {
              label: content.manage_organization,
              onClick: () => store.dispatch(openEditOrgModal())
            }
          })
        },
        pageHero: {
          title: orgName,
          illustration: HubIllustrationConstants.ORGANIZING,
          illustrationAltText: HubIllustrationAltText.ORGANIZING,
          className: styles.kipperYellow
        }
      };
    }
    case featureIsEnabled('navigation-changes') &&
      pathname.includes(HubLayoutConstants.PATH_NAMES.SINGLE_CLASS_PATH) &&
      !!classData.name: {
      const { orgId, orgShortCode } = orgData;
      const { userId, userRole } = userData;
      const { joiningCode, classId, name } = classData;
      const classCode = `${orgShortCode}-${joiningCode?.code}`;

      const roleCustomizations = processUserRole(userRole, content);
      const { SINGLE_CLASS: { showManageClassButton = false } = {} } = roleCustomizations;

      const currentClassList = store.getState().search?.profileClasses?.currentClassList ?? {};
      const teacherIdList = get(currentClassList, [classId, 'teacherIdList'], []);
      const isTeacherAssignedToClass =
        includes(teacherIdList, userId) || roleIsAtLeast(userRoles.TEACHER_ADMIN, userRole);

      const isLti = isLtiMode() || currentOrganisationLti;
      const showSettingsButton = showManageClassButton && isTeacherAssignedToClass && !isLti;
      const showClassCodeButton = [userRoles.ORG_ADMIN, userRoles.TEACHER_ADMIN].includes(userRole) && !isLti;

      return {
        showInnerPageHero: true,
        secondaryNavigation: {
          links: getClassesLinksData({
            content,
            pathname,
            options: { classId, orgId, userRole, roleCustomizations }
          }),
          ...(showSettingsButton && {
            settingsButton: {
              label: content.manage_class,
              onClick: () => store.dispatch(openEditClassroomModal())
            }
          })
        },
        pageHero: {
          title: name,
          className: styles.neutralBlue,
          ...(showClassCodeButton && {
            children: (
              <>
                <span>
                  <strong>Joining code:</strong> {classCode}
                </span>
                <CopyToClipboardButton
                  classCode={classCode}
                  customButtonColor="#002147"
                  buttonLabel="Copy"
                  size="small"
                />
              </>
            )
          })
        },
        allowContentFullWidth: pathname.includes(HubLayoutConstants.PATH_NAMES.GRADEBOOK)
      };
    }
    default: {
      return {
        showInnerPageHero: true,
        pageHero: {
          title: content.loading,
          className: styles.neutralBlue
        },
        secondaryNavigation: {
          links: []
        }
      };
    }
  }
}

export default getPageLayoutData;
