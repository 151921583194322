import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { useWizard } from '@oup/shared-front-end/src/components/Wizard';
import WizardStep from '@oup/shared-front-end/src/components/Wizard/WizardStep/WizardStep.js';
import APP_CONSTANTS from '@oup/shared-node-browser/constants';
import animationTimeout from '../../ClassOnboardingWizard/animationUtils/animationTimeout';
import PlacementTestChooseTypeOfTestTakerPanel from './PlacementTestChooseTypeOfTestTakerPanel/PlacementTestChooseTypeOfTestTakerPanel';
import { getWizardTestCreditsRequest } from '../../../redux/reducers/placementTestSessionCreate';
import { clearForm } from '../../../redux/reducers/addStudentsToClassroom';
import { getStudentsInTestSession } from '../../../redux/actions/placementTests';

import style from './AddStudents.scss';
import animationStyles from '../../ClassOnboardingWizard/animationUtils/animationStyles.scss';
import withLocalizedContent from '../../../language/withLocalizedContent';
import LicenceCounting from './LicenceCounting/LicenceCounting';
import ManageAddedStudentsPanel from './ManageAddedStudentsPanel/ManageAddedStudentsPanel.js';
import PlacementTestSetNumberOfPlacesForUnknownStudentsPanel from './SetNumberOfPlacesForUnknownStudentsPanel/SetNumberOfPlacesForUnknownStudentsPanel';
import EnrolUser from '../../../routes/MySchool/tabs/StaffTab/panels/EnrolUser/EnrolUser';
import AddExistingStudentsPanel from '../../../routes/ClassroomPage/tabs/StudentsTab/panels/AddStudents/AddStudents';
import SidePanel from '../../SidePanel/SidePanel.js';
import { getPlacementTestSessionRequest } from '../../../redux/actions/placementTestOnboardingWizard.js';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner.js';

function AddStudents({
  placementTestSessionName,
  clearFormAction,
  placementTestSessionIdCreated,
  placementTestSessionId,
  localizedContent: { placementTests: placementTestsContent },
  getTestCreditsForProductAction,
  placementTestProductId,
  currentOrganisationId,
  getPlacementTestSessionRequestAction,
  studentsInTestSession,
  loading,
  getStudentsInTestSessionAction
}) {
  const { handleStep } = useWizard();
  const [stepOut, setStepOut] = useState(false);
  const fadeOut = stepOut ? animationStyles.fadeOut : '';
  const [activePanel, setActivePanel] = useState(null);
  const [backActionForNumberOfPlaces, setBackActionForNumberOfPlaces] = useState(() => () => {});
  const sessionId = placementTestSessionId || placementTestSessionIdCreated;

  const openPanel = (panelType, backAction = null) => {
    setActivePanel(panelType);
    if (panelType === 'numberOfPlaces' && backAction) {
      setBackActionForNumberOfPlaces(() => backAction);
    }
  };

  const [currentPage, setCurrentPage] = useState(1);

  const paginate = pageNumber => {
    setCurrentPage(pageNumber);
    getStudentsInTestSessionAction({ classId: sessionId, page: Number(pageNumber) - 1 });
  };

  useEffect(() => {
    getTestCreditsForProductAction({ productId: placementTestProductId, orgId: currentOrganisationId });
  }, []);

  const closePanel = () => {
    setActivePanel(null);
    setBackActionForNumberOfPlaces(null);
    getPlacementTestSessionRequestAction({ orgId: currentOrganisationId, sessionId });
  };

  const closeExistingStudentsPanel = () => {
    closePanel();
    clearFormAction();
  };

  handleStep(async () => {
    setStepOut(true);
    await animationTimeout();
  });

  useEffect(() => {
    const interval = setInterval(() => {
      getPlacementTestSessionRequestAction({ orgId: currentOrganisationId, sessionId });
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  const getPanel = () => {
    let Component;
    let componentArgs = {};
    let hideCloseButton = false;
    switch (activePanel) {
      case 'manageAddedStudents':
        Component = loading ? LoadingSpinner : ManageAddedStudentsPanel;
        componentArgs = loading
          ? {}
          : {
              placementTestSessionName,
              studentsDetails: studentsInTestSession.students,
              totalResults: studentsInTestSession.totalNumberOfStudents,
              failed: false,
              isOpen: activePanel === 'manageAddedStudents',
              isManageSessionEditPanel: false,
              cancelPanel: closePanel,
              onAddStudents: () => openPanel('chooseTestTaker'),
              paginate,
              currentPage
            };
        break;
      case 'chooseTestTaker':
        Component = PlacementTestChooseTypeOfTestTakerPanel;
        componentArgs = {
          onNext: selectedOption => {
            switch (selectedOption) {
              case 'unknownStudents':
                openPanel('numberOfPlaces', () => openPanel('chooseTestTaker'));
                break;
              case 'newStudents':
                openPanel('newStudents');
                break;
              case 'existingStudents':
                openPanel('existingStudents');
                break;
              default:
                break;
            }
          },
          onClose: closePanel,
          isManageSessionEditPanel: false
        };
        break;
      case 'numberOfPlaces':
        Component = PlacementTestSetNumberOfPlacesForUnknownStudentsPanel;
        componentArgs = {
          onBack: backActionForNumberOfPlaces || closePanel,
          placementTestSessionName,
          onClose: () => {
            closePanel();
          }
        };
        break;
      case 'newStudents':
        Component = EnrolUser;
        componentArgs = {
          orgId: currentOrganisationId,
          context: APP_CONSTANTS.ORG_STUDENTS,
          closePanel,
          backAction: () => openPanel('chooseTestTaker'),
          displayBackButton: true,
          onComplete: closePanel,
          className: style.enrollStudent
        };
        hideCloseButton = true;
        break;
      case 'existingStudents':
        Component = AddExistingStudentsPanel;
        componentArgs = {
          isOpen: true,
          closePanel: closeExistingStudentsPanel,
          backAction: () => openPanel('chooseTestTaker'),
          onComplete: closePanel,
          orgId: currentOrganisationId,
          classId: placementTestSessionId,
          isPlacementTest: true,
          placementTestSessionName
        };
        hideCloseButton = true;
        break;

      default:
        return null;
    }

    return (
      <SidePanel id={activePanel} isOpen={!!activePanel} onClose={closePanel} hideCloseButton={hideCloseButton}>
        <Component {...componentArgs} />
      </SidePanel>
    );
  };

  return (
    <div
      data-testid="ONBOARDING_WIZARD_CREATE_A_PLACEMENT_TEST_SESSION_ADD_STUDENTS_CONTAINER"
      className={`${fadeOut}`}
    >
      <WizardStep
        titleText={`${placementTestsContent.placement_test_add_students_page_title} ${placementTestSessionName ||
          placementTestsContent.placement_test_notification_page_session_name_fallback}`}
      >
        <div className={style.addStudentsContainer}>
          <LicenceCounting
            setShowManageAddedStudentsPanel={() => {
              getStudentsInTestSessionAction({ classId: sessionId });
              openPanel('manageAddedStudents');
            }}
            setShowNumberOfPlacesForUnknownStudentsPanel={() => openPanel('numberOfPlaces', closePanel)}
            setIsChooseTestTakerPanelOpen={() => openPanel('chooseTestTaker')}
          />
          {getPanel()}
        </div>
      </WizardStep>
    </div>
  );
}

AddStudents.propTypes = {
  placementTestSessionName: PropTypes.string,
  placementTestSessionIdCreated: PropTypes.string,
  placementTestSessionId: PropTypes.string,
  localizedContent: PropTypes.object.isRequired,
  placementTestProductId: PropTypes.string,
  currentOrganisationId: PropTypes.string,
  getTestCreditsForProductAction: PropTypes.func,
  clearFormAction: PropTypes.func,
  getPlacementTestSessionRequestAction: PropTypes.func,
  placesForUnknownStudents: PropTypes.number,
  studentsInTestSession: PropTypes.object,
  loading: PropTypes.bool,
  getStudentsInTestSessionAction: PropTypes.func
};

export default compose(
  withLocalizedContent('placementTests'),
  connect(
    ({
      placementTestSessionCreate,
      identity,
      placementOnboardingWizard,
      loadPlacementTestsReducer: { studentsInTestSession, loading }
    }) => ({
      placementTestSessionName: placementTestSessionCreate.placementTestSessionNameValue,
      currentOrganisationId: identity.currentOrganisationId,
      placesForUnknownStudents: placementTestSessionCreate.placementTest.placesForUnknownStudents,
      placementTestSessionIdCreated: placementOnboardingWizard.placementTestSessionIdCreated,
      placementTestSessionId: placementTestSessionCreate.placementTestSessionId,
      placementTestProductId: placementTestSessionCreate.editedPlacementTest.productId,
      studentsInTestSession,
      loading
    }),
    {
      getTestCreditsForProductAction: getWizardTestCreditsRequest,
      clearFormAction: clearForm,
      getPlacementTestSessionRequestAction: getPlacementTestSessionRequest,
      getStudentsInTestSessionAction: getStudentsInTestSession
    }
  )
)(AddStudents);
